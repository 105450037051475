import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import loadable from '@loadable/component';

import { useRouter } from '@bibliocommons/utils-routing';

import ErrorPage from 'app/components/ErrorPage';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import connectRoute from 'app/routes/connectRoute';
import EventsActions from 'app/actions/EventsActions';
import { trackEventSearch } from 'app/actions/AnalyticsGa4Actions';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import { selectActiveFilters } from 'app/selectors/events/EventsSearchSelector';

const EventsSearchPageContainer = loadable(() =>
  import('app/components/events/EventsSearchPage/EventsSearchPageContainer')
);

export function EventsSearchPageRoute() {
  const { query } = useRouter();
  const dispatch = useDispatch();
  const currentLibrary = useSelector(selectCurrentLibrary);
  const searchQuery = query.q;

  useEffect(() => {
    if (searchQuery) {
      dispatch(trackEventSearch({ searchQuery }));
    }
  }, [searchQuery, dispatch]);

  if (!currentLibrary.getIn(['events', 'enabled'])) {
    return <ErrorPage httpStatusCode={HTTP_STATUS_CODE.FORBIDDEN} pageMessageKey="events" />;
  }

  return <EventsSearchPageContainer />;
}

function getRouteData({ dispatch, state, query }) {
  const activeFilters = selectActiveFilters(state);
  const currentLibrary = selectCurrentLibrary(state);

  if (!currentLibrary.getIn(['events', 'enabled'])) return [];

  if (activeFilters.isEmpty()) {
    return [dispatch(EventsActions.searchEvents(query)), dispatch(EventsActions.getCalloutFeaturedEvent())];
  }
  return [dispatch(EventsActions.searchEvents(query))];
}

export default connectRoute(EventsSearchPageRoute, {
  getRouteData,
  debounceOptions: {
    wait: 100 // milliseconds
  }
});
