import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import manageFocus from '@bibliocommons/base-accessibility-manage-focus';
import Swipeable from '@bibliocommons/base-gestures-swipeable';
import Portal from '@bibliocommons/base-portal';
import { childrenShape } from '@bibliocommons/bc-prop-types';

import '../Sidebar.scss';

const Sidebar = ({ captureFocus, children, drawerActions, open, restoreFocus, sidebarClass }) => {
  const isOpen = open;

  const sidebarRef = useRef(null);

  useEffect(() => {
    if (!sidebarRef.current) {
      return;
    }

    setTimeout(() => {
      if (isOpen) {
        captureFocus(sidebarRef.current);
      } else {
        restoreFocus();
      }
    }, 500);
  }, [captureFocus, isOpen, restoreFocus, sidebarRef]);

  const swipableSidebarClassNames = cn(sidebarClass, [
    'cp-sidebar-swipeable',
    ['col-10', 'col-xs-10', 'col-sm-8', 'col-md-7', 'col-lg-3'],
    {
      'cp-sidebar-swipeable--open': isOpen
    }
  ]);

  return (
    <>
      <div className="hidden-xs hidden-sm hidden-md col-lg-3">{children}</div>
      <Portal focusTrapActive={isOpen}>
        <Swipeable className={swipableSidebarClassNames} onSwipedLeft={drawerActions.toggle}>
          {/* eslint-disable */}
          <div className="sidebar-backdrop" onClick={drawerActions.toggle} />
          {/* eslint-enable */}
          <section className="sidebar-overlay" ref={sidebarRef}>
            {children}
          </section>
        </Swipeable>
      </Portal>
    </>
  );
};

Sidebar.defaultProps = {
  sidebarClass: ''
};

Sidebar.propTypes = {
  captureFocus: PropTypes.func.isRequired,
  drawerActions: PropTypes.shape({
    toggle: PropTypes.func.isRequired
  }).isRequired,
  sidebarClass: PropTypes.string,
  children: childrenShape.isRequired,
  restoreFocus: PropTypes.func.isRequired,
  open: PropTypes.bool
};

export default manageFocus(Sidebar);
