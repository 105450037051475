import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorPage from 'app/components/ErrorPage';
import MainLayout from 'app/components/layouts/MainLayout';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import CommentPageRoute from './CommentPageRoute';

const basePath = '/v2/comment';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Route path={`${basePath}/:commentId`}>
          <CommentPageRoute />
        </Route>
        <Route>
          <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
