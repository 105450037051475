function formatBibEntity(bibEntityProps) {
  if (!bibEntityProps) {
    return undefined;
  }

  return {
    bib_audience: bibEntityProps.bib_audience ?? undefined,
    bib_availability_status: bibEntityProps.bib_availability_status ?? undefined,
    bib_fiction_type: bibEntityProps.bib_fiction_type ?? undefined,
    bib_format: bibEntityProps.bib_format ?? undefined,
    bib_fulfillment_provider: bibEntityProps.bib_fulfillment_provider ?? undefined,
    bib_group_key: bibEntityProps.bib_group_key ?? undefined,
    bib_hold_count: bibEntityProps.bib_hold_count ?? undefined,
    bib_metadata_id: bibEntityProps.bib_metadata_id ?? undefined,
    bib_total_item_count: bibEntityProps.bib_total_item_count ?? undefined
  };
}

function formatCardEntity(cardEntityProps) {
  if (!cardEntityProps) {
    return undefined;
  }

  return {
    card_id: cardEntityProps.card_id ?? undefined,
    card_title: cardEntityProps.card_title ?? undefined,
    card_type: cardEntityProps.card_type ?? undefined
  };
}

function formatCommunityContentEntity(communityContentEntityProps) {
  if (!communityContentEntityProps) {
    return undefined;
  }

  return {
    community_content_id: communityContentEntityProps.community_content_id ?? undefined,
    community_content_type: communityContentEntityProps.community_content_type ?? undefined
  };
}

function formatEventEntity(eventEntityProps) {
  if (!eventEntityProps) {
    return undefined;
  }

  return {
    event_id: eventEntityProps.event_id ?? undefined,
    event_location: eventEntityProps.event_location ?? undefined,
    event_registration_capacity: eventEntityProps.event_registration_capacity ?? undefined,
    event_registration_count: eventEntityProps.event_registration_count ?? undefined,
    event_registration_type: eventEntityProps.event_registration_type ?? undefined,
    event_series_id: eventEntityProps.event_series_id ?? undefined,
    event_series_title: eventEntityProps.event_series_title ?? undefined,
    event_title: eventEntityProps.event_title ?? undefined
  };
}

function formatLocationEntity(eventLocationEntityProps) {
  if (!eventLocationEntityProps) {
    return undefined;
  }

  return {
    location_id: eventLocationEntityProps.location_id ?? undefined,
    location_name: eventLocationEntityProps.location_name ?? undefined
  };
}

function formatListEntity(listEntityProps) {
  if (!listEntityProps) {
    return undefined;
  }

  return {
    list_id: listEntityProps.list_id ?? undefined,
    list_purpose: listEntityProps.list_purpose ?? undefined,
    list_title: listEntityProps.list_title ?? undefined,
    list_visibility: listEntityProps.list_visibility ?? undefined
  };
}

function formatMetadataEntity(metadataEntityProps) {
  if (!metadataEntityProps) {
    return undefined;
  }

  return {
    metadata_category_type: metadataEntityProps.metadata_category_type ?? undefined,
    metadata_enrichment_provider: metadataEntityProps.metadata_enrichment_provider ?? undefined,
    metadata_value: metadataEntityProps.metadata_value ?? undefined
  };
}

function formatOrganizationEntity(organizationEntityProps) {
  if (!organizationEntityProps) {
    return undefined;
  }

  return {
    subdomain: organizationEntityProps.subdomain ?? undefined
  };
}

function formatProductEntity(productEntityProps) {
  if (!productEntityProps) {
    return undefined;
  }

  return {
    product_id: productEntityProps.product_id ?? undefined,
    product_version: productEntityProps.product_version ?? undefined
  };
}

function formatSearchEntity(searchEntityProps) {
  if (!searchEntityProps) {
    return undefined;
  }

  return {
    search_origin: searchEntityProps.search_origin ?? undefined,
    search_query: searchEntityProps.search_query ?? undefined,
    search_type: searchEntityProps.search_type ?? undefined
  };
}

function formatUiEntity(uiEntityProps) {
  if (!uiEntityProps) {
    return undefined;
  }

  return {
    ui_component_label: uiEntityProps.ui_component_label ?? undefined,
    ui_component_type: uiEntityProps.ui_component_type ?? undefined,
    ui_container_position: uiEntityProps.ui_container_position ?? undefined,
    ui_container_title: uiEntityProps.ui_container_title ?? undefined,
    ui_container_type: uiEntityProps.ui_container_type ?? undefined,
    ui_content_layout: uiEntityProps.ui_content_layout ?? undefined,
    ui_content_page: uiEntityProps.ui_content_page ?? undefined,
    ui_content_position: uiEntityProps.ui_content_position ?? undefined,
    ui_content_sort: uiEntityProps.ui_content_sort ?? undefined,
    ui_content_total_count: uiEntityProps.ui_content_total_count ?? undefined
  };
}

function formatUserEntity(userEntityProps) {
  return {
    user_analytics_id: userEntityProps?.user_analytics_id ?? undefined,
    user_location: userEntityProps?.user_location ?? undefined,
    user_type: userEntityProps?.user_type ?? undefined
  };
}

function mapEventToEntity({ event, series }) {
  const eventDefinition = event.get('definition');
  const location = eventDefinition.get('isVirtual')
    ? 'online'
    : eventDefinition.get('branchLocationId') || eventDefinition.get('nonBranchLocationId');

  let registrationType;
  if (eventDefinition.getIn(['registrationInfo', 'provider']) === 'BIBLIO_EVENTS') {
    registrationType = 'online';
    if (eventDefinition.getIn(['registrationInfo', 'waitlistEnabled'])) {
      registrationType = 'online-with-waitlist';
    }
  }
  if (eventDefinition.getIn(['registrationInfo', 'provider']) === 'EXTERNAL') {
    registrationType = 'offline';
  }
  const title = series?.getIn(['definition', 'title']) || eventDefinition.get('title');

  return {
    event_id: event.get('id'),
    event_location: location ?? undefined,
    event_registration_capacity: eventDefinition.get('registrationInfo').get('cap') || undefined,
    event_registration_count: event.get('numberRegistered') ?? undefined,
    event_registration_type: registrationType ?? undefined,
    event_series_id: event.get('seriesId') ?? undefined,
    event_series_title: title ?? undefined,
    event_title: eventDefinition.get('title') ?? undefined
  };
}

export {
  formatBibEntity,
  formatCardEntity,
  formatCommunityContentEntity,
  formatEventEntity,
  formatListEntity,
  formatMetadataEntity,
  formatOrganizationEntity,
  formatProductEntity,
  formatSearchEntity,
  formatUiEntity,
  formatUserEntity,
  formatLocationEntity,
  mapEventToEntity
};
