import React from 'react';
import { Route } from 'react-router-dom';
import EventsPdfPageRoute from './EventsPdfPageRoute';

const basePath = '/v2/events/pdf';

export default (
  <Route exact path={basePath}>
    <EventsPdfPageRoute />
  </Route>
);
