import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ErrorPage from 'app/components/ErrorPage';
import MainLayout from 'app/components/layouts/MainLayout';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import appsRoutes from './apps';

const basePath = '/v2/info';

export default (
  <Route path={basePath}>
    <Switch>
      <Redirect exact from={basePath} to={`${basePath}/apps`} />
      {appsRoutes}
      <Route>
        <MainLayout>
          <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />
        </MainLayout>
      </Route>
    </Switch>
  </Route>
);
