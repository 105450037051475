import Immutable from 'immutable';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { TOGGLZ_FEATURES } from '@bibliocommons/constants-feature-togglz';
import ProgressBar from '@bibliocommons/shared-progress-bar';

import { trackContentClick, trackContentExplore, trackNavigate } from 'app/actions/AnalyticsGa4Actions';
import { BackToLinkManager } from 'app/components/BackToLinkManager';
import ErrorPage from 'app/components/ErrorPage';
import { DocumentHeadContainer } from 'app/components/shared/DocumentHead';
import { METADATA_CATEGORY_TYPE } from 'app/constants/AnalyticsGa4Constants';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import useFeature from 'app/hooks/useFeature';
import { selectBrowseConfig, selectIsFetching } from 'app/selectors/BrowseSelector';
import { selectCatalogBibEntities, selectListEntities, selectUserEntities } from 'app/selectors/EntitiesSelector';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import BrowsePage from './BrowsePage';

const ANALYTICS_UI_CONTAINER_TYPE = {
  BIB: 'browse-row-bib',
  LINK: 'browse-row-link',
  LIST: 'browse-row-list',
  NAV: 'browse-row-navigation'
};

const BrowsePageContainer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentLibrary = useSelector(selectCurrentLibrary);
  const config = useSelector(selectBrowseConfig) ?? Immutable.Map();
  const catalogBibsByMetadataId = useSelector(selectCatalogBibEntities);
  const isFetching = useSelector(selectIsFetching);
  const listsById = useSelector(selectListEntities);
  const usersById = useSelector(selectUserEntities);
  const isCoreV2BrowsePageEnabled = useFeature(TOGGLZ_FEATURES.CORE_V2_BROWSE_PAGE);

  const pageTitle = intl.formatMessage({ defaultMessage: 'Browse', id: 'browse' });

  if (!isCoreV2BrowsePageEnabled) {
    return <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />;
  }

  const trackBibItemClick = ({ catalogBib, itemPosition, rowPosition, rowTitle }) => {
    const metadataId = catalogBib.get('metadataId');
    const bibTitle = catalogBib.getIn(['brief', 'otherScriptTitle']) || catalogBib.getIn(['brief', 'title']) || '';

    dispatch(
      trackContentClick({
        bibId: metadataId,
        metadata: {
          metadata_category_type: METADATA_CATEGORY_TYPE.BIBLIOGRAPHIC_DETAILS_TITLE,
          metadata_value: bibTitle
        },
        ui: {
          ui_component_type: 'link-image',
          ui_container_position: rowPosition,
          ui_container_title: rowTitle,
          ui_container_type: ANALYTICS_UI_CONTAINER_TYPE.BIB,
          ui_content_position: itemPosition
        }
      })
    );
  };

  const trackBibsRowTitleClick = ({ linkText, linkUrl, rowPosition, rowTitle }) => {
    dispatch(
      trackContentExplore({
        metadata: {
          metadata_category_type: 'browse',
          metadata_value: linkUrl
        },
        ui: {
          ui_component_label: linkText,
          ui_component_type: 'link-heading',
          ui_container_position: rowPosition,
          ui_container_title: rowTitle,
          ui_container_type: ANALYTICS_UI_CONTAINER_TYPE.BIB
        }
      })
    );
  };

  const trackLinksRowLinkClick = ({ itemPosition, linkText, linkUrl, rowPosition, rowTitle }) => {
    dispatch(
      trackContentExplore({
        metadata: {
          metadata_category_type: 'browse',
          metadata_value: linkUrl
        },
        ui: {
          ui_component_label: linkText,
          ui_component_type: 'link-text',
          ui_container_position: rowPosition,
          ui_container_title: rowTitle,
          ui_container_type: ANALYTICS_UI_CONTAINER_TYPE.LINK,
          ui_content_position: itemPosition
        }
      })
    );
  };

  const trackListCreatorClick = ({ creatorName, itemPosition, list, rowPosition, rowTitle }) => {
    dispatch(
      trackContentExplore({
        list: {
          list_id: list.id,
          list_purpose: (list.listType || '').toLowerCase(),
          list_title: list.name,
          list_visibility: list.visibility
        },
        metadata: {
          metadata_category_type: 'user',
          metadata_value: creatorName
        },
        ui: {
          ui_component_label: creatorName,
          ui_component_type: 'link-text',
          ui_container_position: rowPosition,
          ui_container_title: rowTitle,
          ui_container_type: ANALYTICS_UI_CONTAINER_TYPE.LIST,
          ui_content_position: itemPosition
        }
      })
    );
  };

  const trackListImageClick = ({ itemPosition, list, rowPosition, rowTitle }) => {
    dispatch(
      trackContentClick({
        list: {
          list_id: list.id,
          list_purpose: (list.listType || '').toLowerCase(),
          list_title: list.name,
          list_visibility: list.visibility
        },
        ui: {
          ui_component_type: 'link-image',
          ui_container_position: rowPosition,
          ui_container_title: rowTitle,
          ui_container_type: ANALYTICS_UI_CONTAINER_TYPE.LIST,
          ui_content_position: itemPosition
        }
      })
    );
  };

  const trackListTitleClick = ({ itemPosition, list, rowPosition, rowTitle }) => {
    dispatch(
      trackContentClick({
        list: {
          list_id: list.id,
          list_purpose: (list.listType || '').toLowerCase(),
          list_title: list.name,
          list_visibility: list.visibility
        },
        ui: {
          ui_component_label: list.name,
          ui_component_type: 'link-text',
          ui_container_position: rowPosition,
          ui_container_title: rowTitle,
          ui_container_type: ANALYTICS_UI_CONTAINER_TYPE.LIST,
          ui_content_position: itemPosition
        }
      })
    );
  };

  const trackNavLinkClick = ({ itemPosition, linkText, linkUrl, rowPosition, rowTitle }) => {
    dispatch(
      trackNavigate({
        metadata: {
          metadata_category_type: 'navigation',
          metadata_value: linkUrl
        },
        ui: {
          ui_component_label: linkText,
          ui_component_type: 'link-text',
          ui_container_position: rowPosition,
          ui_container_title: rowTitle,
          ui_container_type: ANALYTICS_UI_CONTAINER_TYPE.NAV,
          ui_content_position: itemPosition
        }
      })
    );
  };

  return (
    <>
      <DocumentHeadContainer
        description={intl.formatMessage(
          { id: 'browse_and_discover_description' },
          { libraryName: currentLibrary?.get('fullName') }
        )}
        pageTitle={pageTitle}
      />
      <ProgressBar active={isFetching} />
      <BackToLinkManager pageId="browse" />
      <BrowsePage
        catalogBibsByMetadataId={catalogBibsByMetadataId}
        config={config}
        listsById={listsById}
        pageTitle={pageTitle}
        trackBibItemClick={trackBibItemClick}
        trackBibsRowTitleClick={trackBibsRowTitleClick}
        trackLinksRowLinkClick={trackLinksRowLinkClick}
        trackListCreatorClick={trackListCreatorClick}
        trackListImageClick={trackListImageClick}
        trackListTitleClick={trackListTitleClick}
        trackNavLinkClick={trackNavLinkClick}
        usersById={usersById}
      />
    </>
  );
};

export default BrowsePageContainer;
