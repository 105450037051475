import PropTypes from 'prop-types';
import React from 'react';

import { childrenShape } from '@bibliocommons/bc-prop-types';

import '../styles/Layout.scss';

class Layout extends React.PureComponent {
  render() {
    return (
      <div className={`cp-layout ${this.props.containerClass} drawer-layout-left row`}>
        {React.Children.map(this.props.children, child => React.cloneElement(child, { open: this.props.open }))}
      </div>
    );
  }
}

Layout.propTypes = {
  open: PropTypes.bool,
  containerClass: PropTypes.string,
  children: childrenShape
};

export default Layout;
