import React from 'react';
import loadable from '@loadable/component';

import { userIdentityShape, childrenShape } from '@bibliocommons/bc-prop-types';

import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import connectRoute from 'app/routes/connectRoute';
import UserActions from 'app/actions/UserActions';
import { selectRequestedUser } from 'app/selectors/UserSelector';

const ErrorPage = loadable(() => import('app/components/ErrorPage'));

export function UsersPageRoute(props) {
  const { user } = props;
  if (user && user.get('active')) {
    return props.children;
  }

  return <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />;
}

function getRouteData({ dispatch, params }) {
  return [dispatch(UserActions.fetchUser(params.userId))];
}

UsersPageRoute.propTypes = {
  children: childrenShape.isRequired,
  user: userIdentityShape
};

const mapStateToProps = state => ({
  user: selectRequestedUser(state)
});

export default connectRoute(UsersPageRoute, { getRouteData, mapStateToProps });
