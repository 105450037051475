import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ErrorPage from 'app/components/ErrorPage';
import MainLayout from 'app/components/layouts/MainLayout';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import UnregisterPageRoute from './UnregisterPageRoute';

const basePath = '/v2/info/apps';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Redirect exact from={basePath} to={`${basePath}/unregister`} />
        <Route path={`${basePath}/unregister`}>
          <UnregisterPageRoute />
        </Route>
        <Route>
          <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
