import FinesApi from 'app/api/FinesApi';
import FinesConstants from 'app/constants/FinesConstants';
import composeGlobalSuccessMessaging from 'app/helpers/messaging/composeGlobalSuccessMessaging';

export function fetchTotalFines() {
  return {
    types: [FinesConstants.FETCH_FINES_REQUEST, FinesConstants.FETCH_FINES_SUCCESS, FinesConstants.FETCH_FINES_FAILURE],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => FinesApi.getFines(apiClient, '')
  };
}

export function fetchFines(query) {
  return {
    types: [FinesConstants.FETCH_FINES_REQUEST, FinesConstants.FETCH_FINES_SUCCESS, FinesConstants.FETCH_FINES_FAILURE],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => FinesApi.getFines(apiClient, query)
  };
}

export function updatePage(query) {
  return {
    types: [FinesConstants.UPDATE_PAGE_REQUEST, FinesConstants.UPDATE_PAGE_SUCCESS, FinesConstants.UPDATE_PAGE_FAILURE],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => FinesApi.getFines(apiClient, query)
  };
}

export function fetchFinesPaymentForm(query) {
  return {
    messagingId: 'finesNotice',
    types: [
      FinesConstants.FETCH_FINES_PAYMENT_FORM_REQUEST,
      FinesConstants.FETCH_FINES_PAYMENT_FORM_SUCCESS,
      FinesConstants.FETCH_FINES_PAYMENT_FORM_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => FinesApi.getFinesPaymentForm(apiClient, query)
  };
}

const createGlobalPaymentsOrder = ({ accountId, billIds }) => {
  return {
    apiRequest: (_state, apiClient) => FinesApi.createGlobalPaymentsOrder(apiClient, { accountId, billIds }),
    middlewareData: {
      accountRequired: true
    },
    types: [
      FinesConstants.CREATE_GLOBAL_PAYMENTS_ORDER_REQUEST,
      FinesConstants.CREATE_GLOBAL_PAYMENTS_ORDER_SUCCESS,
      FinesConstants.CREATE_GLOBAL_PAYMENTS_ORDER_FAILURE
    ]
  };
};

const createGlobalPaymentsPayment = ({ accountId, billIds, hppResponse, postFailure, postSuccess }) => {
  return {
    apiRequest: (_state, apiClient) =>
      FinesApi.createGlobalPaymentsPayment(apiClient, { accountId, billIds, hppResponse }),
    middlewareData: {
      accountRequired: true
    },
    postFailure,
    postSuccess,
    types: [
      FinesConstants.CREATE_GLOBAL_PAYMENTS_PAYMENT_REQUEST,
      FinesConstants.CREATE_GLOBAL_PAYMENTS_PAYMENT_SUCCESS,
      FinesConstants.CREATE_GLOBAL_PAYMENTS_PAYMENT_FAILURE
    ]
  };
};

function createOrder({ accountId, billIds, postSuccess }) {
  return {
    postSuccess,
    types: [
      FinesConstants.CREATE_ORDER_REQUEST,
      FinesConstants.CREATE_ORDER_SUCCESS,
      FinesConstants.CREATE_ORDER_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => FinesApi.createOrder(apiClient, { accountId, billIds })
  };
}

function createPayment({ accountId, billIds, orderId, postSuccess, postFailure }) {
  return {
    postSuccess,
    postFailure,
    types: [
      FinesConstants.CREATE_PAYMENT_REQUEST,
      FinesConstants.CREATE_PAYMENT_SUCCESS,
      FinesConstants.CREATE_PAYMENT_FAILURE
    ],
    middlewareData: {
      accountRequired: true
    },
    apiRequest: (state, apiClient) => FinesApi.createPayment(apiClient, { accountId, billIds, orderId }),
    apiSuccess: () => ({
      messaging: composeGlobalSuccessMessaging('fees_payment_successful')
    })
  };
}

function clearFinesTransaction() {
  return {
    type: FinesConstants.CLEAR_FINES_TRANSACTION
  };
}

export default {
  fetchFines,
  fetchTotalFines,
  createOrder,
  createPayment,
  clearFinesTransaction
};

export { createGlobalPaymentsOrder, createGlobalPaymentsPayment };
