import { actionTypes, CONTENT_GROUP, eventNames } from 'app/constants/AnalyticsGa4Constants';
import AuthConstants from 'app/constants/AuthConstants';
import CheckoutConstants from 'app/constants/CheckoutConstants';
import RatingsConstants from 'app/constants/RatingsConstants';
import RelationshipsConstants from 'app/constants/RelationshipsConstants';
import HoldsConstants from 'app/constants/HoldsConstants';
import CommentsConstants from 'app/constants/CommentsConstants';
import FeedbackConstants from 'app/constants/FeedbackConstants';
import ShelfConstants from 'app/constants/ShelfConstants';
import {
  formatBibEntity,
  formatCardEntity,
  formatCommunityContentEntity,
  formatEventEntity,
  formatListEntity,
  formatLocationEntity,
  formatMetadataEntity,
  formatOrganizationEntity,
  formatProductEntity,
  formatSearchEntity,
  formatUiEntity,
  formatUserEntity
} from 'app/helpers/analytics/formatGA4Entity';
import {
  selectBibEntity,
  selectListEntity,
  selectCardEntity,
  selectOrganizationEntity,
  selectProductEntity,
  selectUserEntity
} from 'app/selectors/AnalyticsGa4Selector';
import TagsConstants from 'app/constants/TagsConstants';

const DATA_LAYER_NAME = 'dataLayer';

const pushEvent = (name, { currentUrl = '', entities = {}, properties = undefined } = {}) => {
  let contentGroup;
  if ((currentUrl || '').startsWith('/v2/list/display')) {
    contentGroup = CONTENT_GROUP.LIST;
  }

  const event = {
    content_group: contentGroup ?? undefined,
    event: name,
    entities: {
      bib: formatBibEntity(entities.bib),
      card: formatCardEntity(entities.card),
      community_content: formatCommunityContentEntity(entities.community_content),
      event: formatEventEntity(entities.event),
      location: formatLocationEntity(entities.location),
      list: formatListEntity(entities.list),
      metadata: formatMetadataEntity(entities.metadata),
      organization: formatOrganizationEntity(entities.organization),
      product: formatProductEntity(entities.product),
      search: formatSearchEntity(entities.search),
      ui: formatUiEntity(entities.ui),
      user: formatUserEntity(entities.user)
    },
    event_properties: properties
      ? {
          content_collection_type: properties.content_collection_type ?? undefined,
          fulfillment_location: properties.fulfillment_location ?? undefined,
          impression_id: properties.impression_id ?? undefined,
          metadata_category: properties.metadata_category ?? undefined,
          privacy_status: properties.privacy_status ?? undefined,
          promotion_type: properties.promotion_type ?? undefined
        }
      : undefined
  };

  (window[DATA_LAYER_NAME] || (window[DATA_LAYER_NAME] = [])).push(event);
};

export default function AnalyticsGa4Middleware() {
  return ({ getState }) => next => action => {
    if (__SERVER__) {
      return next(action);
    }

    const result = next(action);
    const { type } = action;
    const state = getState();
    const currentUrl = state.getIn(['app', 'currentURL']);

    switch (type) {
      case AuthConstants.AUTH_LOGIN_SUCCESS: {
        const entities = {
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.USER_LOG_IN, { currentUrl, entities });
        break;
      }

      case CheckoutConstants.DIGITAL_CHECKOUT_SUCCESS: {
        const isFromSearchPage = window?.location?.pathname === '/v2/search';

        const entities = {
          bib: selectBibEntity(state, action.payload.id)?.toJS(),
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: isFromSearchPage && {
            search_origin: state.getIn(['analyticsGa4', 'searchOrigin']),
            search_query: state.getIn(['search', 'catalogSearch', 'query']),
            search_type: state.getIn(['search', 'catalogSearch', 'searchType'])
          },
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.CHECKOUT_PLACE, { currentUrl, entities });
        break;
      }

      case CheckoutConstants.RENEW_SUCCESS: {
        const { payload } = action;
        const checkoutIds = Object.keys(payload?.entities?.checkouts ?? {});

        checkoutIds.forEach(checkoutId => {
          const checkout = payload?.entities?.checkouts?.[checkoutId];
          if (!checkout) {
            return;
          }

          const entities = {
            bib: selectBibEntity(state, checkout.metadataId)?.toJS(),
            organization: selectOrganizationEntity(state).toJS(),
            product: selectProductEntity(state).toJS(),
            user: selectUserEntity(state)?.toJS()
          };

          pushEvent(eventNames.CHECKOUT_RENEW, { currentUrl, entities });
        });

        break;
      }

      case RatingsConstants.RATE_ITEM_SUCCESS: {
        const userId = action.payload?.shelves?.userId;
        const shelfRecordId = action.payload?.shelves?.ids?.[0];
        const isPrivate = action.payload?.entities?.shelves?.[userId]?.[shelfRecordId].privateItem;
        const metadataId = action.payload?.entities?.shelves?.[userId]?.[shelfRecordId].metadataId;
        const ratingId = Object.values(action.payload?.entities?.ugc?.ratings)?.[0]?.id;
        const communityContent = {
          community_content_id: ratingId,
          community_content_type: 'rating'
        };

        const entities = {
          bib: selectBibEntity(state, metadataId)?.toJS(),
          community_content: communityContent,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        const properties = {
          privacy_status: isPrivate ? 'private' : 'public'
        };

        pushEvent(eventNames.USER_CONTENT_ADD, { currentUrl, entities, properties });
        break;
      }

      case RelationshipsConstants.CREATE_FOLLOW_SUCCESS: {
        const entities = {
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.USER_ENGAGE_FOLLOW, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_SDK_CHECKOUT_CONSUME_ACCESS: {
        const entities = {
          bib: action.payload.bib,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.CHECKOUT_CONSUME_ACCESS, { entities });
        break;
      }

      case actionTypes.TRACK_SDK_CHECKOUT_PLACE: {
        const entities = {
          bib: action.payload.bib,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS() ?? undefined
        };

        pushEvent(eventNames.CHECKOUT_PLACE, { entities });
        break;
      }

      case actionTypes.TRACK_SDK_HOLDINGS_CONSUME_ACCESS: {
        const entities = {
          bib: action.payload.bib,
          metadata: action.payload.metadata,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          ui: action.payload.ui,
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.HOLDINGS_CONSUME_ACCESS, { entities });
        break;
      }

      case actionTypes.TRACK_SDK_HOLDINGS_VIEW: {
        const entities = {
          bib: action.payload.bib,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.HOLDINGS_VIEW, { entities });
        break;
      }

      case actionTypes.TRACK_SDK_HOLD_PLACE: {
        const entities = {
          bib: action.payload.bib,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.HOLD_PLACE, { entities, properties: action.payload?.properties });
        break;
      }

      case actionTypes.TRACK_SDK_LOG_IN: {
        const entities = {
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: action.payload?.user ?? selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.USER_LOG_IN, { entities });
        break;
      }

      case actionTypes.TRACK_SDK_USER_ACCOUNT_REGISTER: {
        const entities = {
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: action.payload?.user ?? selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.USER_ACCOUNT_REGISTER, { entities });
        break;
      }

      case actionTypes.TRACK_SDK_USER_CONTENT_ADD: {
        const entities = {
          list: action.payload.list ?? undefined,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: action.payload?.user ?? selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.USER_CONTENT_ADD, { entities });
        break;
      }

      case actionTypes.TRACK_SDK_USER_CONTENT_MODIFY: {
        const entities = {
          list: action.payload.list ?? undefined,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: action.payload?.user ?? selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.USER_CONTENT_MODIFY, { entities });
        break;
      }

      case actionTypes.TRACK_SDK_USER_ENGAGE_FOLLOW: {
        const entities = {
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: action.payload?.user ?? selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.USER_ENGAGE_FOLLOW, { entities });
        break;
      }

      case HoldsConstants.PLACE_HOLD_SUCCESS: {
        const isFromSearchPage = window?.location?.pathname === '/v2/search';

        const entities = {
          bib: selectBibEntity(state, action.payload.id)?.toJS(),
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: isFromSearchPage && {
            search_origin: state.getIn(['analyticsGa4', 'searchOrigin']),
            search_query: state.getIn(['search', 'catalogSearch', 'query']),
            search_type: state.getIn(['search', 'catalogSearch', 'searchType'])
          },
          user: selectUserEntity(state)?.toJS()
        };

        const holdId = Object.keys(action.payload?.entities?.holds ?? {})?.[0];
        const locationCode = action.payload?.entities?.holds?.[holdId]?.pickupLocation?.code;

        const properties = {
          fulfillment_location: locationCode
        };

        pushEvent(eventNames.HOLD_PLACE, { currentUrl, entities, properties });
        break;
      }

      case actionTypes.TRACK_NAVIGATE: {
        const entities = {
          metadata: action.payload?.metadata,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          ui: action.payload?.ui,
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.NAVIGATE, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_REQUEST_EXTERNAL_PLACE: {
        const entities = {
          bib: selectBibEntity(state, action.payload?.bibId)?.toJS(),
          metadata: action.payload?.metadata,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          ui: action.payload?.ui,
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.REQUEST_EXTERNAL_PLACE, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_PAGE_PRINT: {
        const entities = {
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.PAGE_PRINT, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_PAGE_VIEW: {
        const entities = {
          bib: selectBibEntity(state, action.payload?.bibId)?.toJS(),
          list: action.payload?.list,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.PAGE_VIEW, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_CONTENT_CLICK: {
        const entities = {
          bib:
            action.payload?.bib ??
            (action.payload?.bibId ? selectBibEntity(state, action.payload?.bibId)?.toJS() : undefined),
          card: action.payload?.cardId ? selectCardEntity(state, action.payload?.cardId)?.toJS() : undefined,
          event: action.payload?.event,
          list:
            action.payload?.list ??
            (action.payload?.listId ? selectListEntity(state, action.payload?.listId)?.toJS() : undefined),
          metadata: action.payload?.metadata,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: action.payload?.search,
          ui: action.payload?.ui,
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.CONTENT_CLICK, { currentUrl, entities, properties: action.payload?.properties });
        break;
      }

      case actionTypes.TRACK_HOLDINGS_CONSUME_ACCESS: {
        const entities = {
          bib: selectBibEntity(state, action.payload?.bibId)?.toJS(),
          metadata: action.payload?.metadata,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: action?.payload.search,
          ui: action.payload?.ui,
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.HOLDINGS_CONSUME_ACCESS, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_HOLDINGS_EXPLORE: {
        const entities = {
          bib: action.payload?.bib,
          ui: action.payload?.ui,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.HOLDINGS_EXPLORE, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_HOLDINGS_EXPLORE_EDITION: {
        const entities = {
          bib: selectBibEntity(state, action.payload?.bibId)?.toJS(),
          ui: action.payload?.ui,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.HOLDINGS_EXPLORE_EDITION, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_HOLDINGS_EXPLORE_FORMAT: {
        const entities = {
          bib: selectBibEntity(state, action.payload?.bibId)?.toJS(),
          ui: action.payload?.ui,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.HOLDINGS_EXPLORE_FORMAT, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_HOLDINGS_VIEW: {
        const entities = {
          bib: selectBibEntity(state, action.payload?.bibId)?.toJS(),
          metadata: action.payload?.metadata,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: action.payload?.search,
          ui: action.payload?.ui,
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.HOLDINGS_VIEW, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_CHECKOUT_CONSUME_ACCESS: {
        const isFromSearchPage = window?.location?.pathname === '/v2/search';

        const entities = {
          bib: selectBibEntity(state, action.payload?.bibId)?.toJS(),
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: isFromSearchPage && {
            search_origin: state.getIn(['analyticsGa4', 'searchOrigin']),
            search_query: state.getIn(['search', 'catalogSearch', 'query']),
            search_type: state.getIn(['search', 'catalogSearch', 'searchType'])
          },
          user: selectUserEntity(state)?.toJS()
        };
        pushEvent(eventNames.CHECKOUT_CONSUME_ACCESS, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_CHECKOUT_CONSUME_DOWNLOAD: {
        const entities = {
          bib: selectBibEntity(state, action.payload?.bibId)?.toJS(),
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };
        pushEvent(eventNames.CHECKOUT_CONSUME_DOWNLOAD, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_CONTENT_IMPRESSION: {
        const entities = {
          card: selectCardEntity(state, action.payload?.cardId)?.toJS(),
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: action.payload?.search,
          ui: action.payload?.ui,
          user: selectUserEntity(state)?.toJS()
        };
        pushEvent(eventNames.CONTENT_IMPRESSION, { currentUrl, entities, properties: action.payload?.properties });
        break;
      }

      case actionTypes.TRACK_CONTENT_EXPLORE: {
        const entities = {
          bib: selectBibEntity(state, action.payload?.bibId)?.toJS(),
          card: selectCardEntity(state, action.payload?.cardId)?.toJS(),
          event: action.payload?.event,
          list: action.payload?.list,
          location: action.payload?.location,
          metadata: action.payload?.metadata,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: action.payload?.search,
          ui: action.payload?.ui,
          user: selectUserEntity(state)?.toJS()
        };
        pushEvent(eventNames.CONTENT_EXPLORE, { currentUrl, entities, properties: action.payload?.properties });
        break;
      }

      case actionTypes.TRACK_CATALOG_EXPLORE: {
        const entities = {
          bib: (action.payload.bib || selectBibEntity(state, action.payload?.bibId)?.toJS()) ?? null,
          metadata: action.payload?.metadata,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: action.payload?.search,
          ui: action.payload?.ui,
          user: selectUserEntity(state)?.toJS()
        };
        pushEvent(eventNames.CATALOG_EXPLORE, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_CATALOG_SEARCH: {
        const entities = {
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: action.payload?.search,
          ui: action.payload?.ui,
          user: selectUserEntity(state)?.toJS()
        };
        pushEvent(eventNames.CATALOG_SEARCH, { currentUrl, entities });
        break;
      }

      case actionTypes.TRACK_CONTENT_COLLECTION_MOVE: {
        const isFromSearchPage = window?.location?.pathname === '/v2/search';
        const entities = {
          bib: selectBibEntity(state, action?.payload?.bibId)?.toJS(),
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          search: isFromSearchPage && {
            search_origin: state.getIn(['analyticsGa4', 'searchOrigin']),
            search_query: state.getIn(['search', 'catalogSearch', 'query']),
            search_type: state.getIn(['search', 'catalogSearch', 'searchType'])
          },
          user: selectUserEntity(state)?.toJS()
        };

        pushEvent(eventNames.CONTENT_COLLECTION_MOVE, { currentUrl, entities, properties: action.payload?.properties });
        break;
      }

      case CommentsConstants.ADD_COMMENT_SUCCESS: {
        const userId = action.payload?.shelves?.userId;
        const shelfRecordId = action.payload?.shelves?.ids?.[0];
        const isPrivate = action.payload?.entities?.shelves?.[userId]?.[shelfRecordId].privateItem;
        const metadataId = action.payload?.entities?.shelves?.[userId]?.[shelfRecordId].metadataId;
        const commentId = Object.values(action.payload?.entities?.ugc?.comments)?.[0]?.id;
        const communityContent = {
          community_content_id: commentId,
          community_content_type: 'comment'
        };

        const entities = {
          bib: selectBibEntity(state, metadataId)?.toJS(),
          community_content: communityContent,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        const properties = {
          privacy_status: isPrivate ? 'private' : 'public'
        };

        pushEvent(eventNames.USER_CONTENT_ADD, { currentUrl, entities, properties });
        break;
      }

      case FeedbackConstants.SUBMIT_FEEDBACK_SUCCESS: {
        const id = action.payload?.feedback?.id;
        const category = action.payload?.entities?.feedback?.[id]?.category;
        const metadata = {
          metadata_category_type: 'feedback_category',
          metadata_value: category
        };
        const entities = {
          metadata,
          organization: selectOrganizationEntity(state).toJS(),
          product: selectProductEntity(state).toJS(),
          user: selectUserEntity(state)?.toJS()
        };

        const properties = {
          metadata_category: 'feedback_category'
        };

        pushEvent(eventNames.SERVICE_FEEDBACK, { currentUrl, entities, properties });
        break;
      }

      case ShelfConstants.ADD_TO_SHELF_SUCCESS: {
        const isFromSearchPage = window?.location?.pathname === '/v2/search';
        const { payload } = action;

        const userId = Object.keys(payload?.entities?.shelves ?? {})?.[0];
        const shelfIds = Object.keys(payload?.entities?.shelves?.[userId] ?? {});

        shelfIds.forEach(shelfId => {
          const shelf = payload?.entities?.shelves?.[userId]?.[shelfId];
          if (!shelf) {
            return;
          }

          const entities = {
            bib: selectBibEntity(state, shelf.metadataId)?.toJS(),
            organization: selectOrganizationEntity(state).toJS(),
            product: selectProductEntity(state).toJS(),
            search: isFromSearchPage && {
              search_origin: state.getIn(['analyticsGa4', 'searchOrigin']),
              search_query: state.getIn(['search', 'catalogSearch', 'query']),
              search_type: state.getIn(['search', 'catalogSearch', 'searchType'])
            },
            user: selectUserEntity(state)?.toJS()
          };

          const properties = {
            content_collection_type: `shelf_${shelf.shelf}`,
            privacy_status: shelf.privateItem ? 'private' : 'public'
          };
          pushEvent(eventNames.CONTENT_COLLECTION_ADD, { currentUrl, entities, properties });
        });

        break;
      }

      case TagsConstants.ADD_TAG_SUCCESS: {
        const { payload } = action;
        const tag = Object.values(payload.entities.ugc.tags)[0];

        if (tag) {
          const userId = payload?.shelves?.userId;
          const shelfId = payload?.shelves?.ids[0];
          const shelf = payload?.entities?.shelves?.[userId]?.[shelfId];
          const communityContentEntity = {
            community_content_id: tag.id,
            community_content_type: 'tag'
          };

          const entities = {
            bib: selectBibEntity(state, tag.metadataId)?.toJS(),
            community_content: communityContentEntity,
            organization: selectOrganizationEntity(state).toJS(),
            product: selectProductEntity(state).toJS(),
            user: selectUserEntity(state)?.toJS()
          };
          const properties = {
            privacy_status: shelf?.privateItem ? 'private' : 'public'
          };

          pushEvent(eventNames.USER_CONTENT_ADD, { currentUrl, entities, properties });
        }
        break;
      }

      case actionTypes.TRACK_EVENT_SEARCH: {
        const { searchQuery } = action.payload;

        if (searchQuery) {
          const pagination = state.getIn(['events', 'eventsSearch', 'pagination']);
          const entities = {
            organization: selectOrganizationEntity(state).toJS(),
            product: selectProductEntity(state).toJS(),
            search: {
              search_origin: 'user-query',
              search_type: 'keyword',
              search_query: searchQuery
            },
            ui: {
              ui_content_layout: 'medium',
              ui_content_page: pagination?.get('page'),
              ui_content_total_count: pagination?.get('count')
            },
            user: selectUserEntity(state)?.toJS()
          };

          pushEvent(eventNames.EVENT_SEARCH, { currentUrl, entities });
        }
        break;
      }

      default: {
        break;
      }
    }

    return result;
  };
}
