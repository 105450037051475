import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { libraryShape } from '@bibliocommons/bc-prop-types';
import Context from '@bibliocommons/context';

export class DocumentHead extends React.PureComponent {
  getDocumentTitle() {
    const { currentLibrary, pageTitle } = this.props;
    if (this.context.mobileApp) {
      return pageTitle;
    }

    const libraryFullName = currentLibrary.get('fullName');

    return pageTitle ? `${pageTitle} | ${libraryFullName} | BiblioCommons` : null;
  }

  getDescription() {
    const { description, currentLibrary, intl } = this.props;
    return (
      description ||
      intl.formatMessage({ id: 'default_page_description' }, { libraryName: currentLibrary.get('fullName') })
    );
  }

  getLinks() {
    const { pageUrl } = this.props;

    return [...(pageUrl ? [{ rel: 'canonical', href: pageUrl }] : [])];
  }

  getMetaTags() {
    const {
      currentLibrary,
      excludeLibraryFromMetaTitle,
      pageTitle,
      pageUrl,
      imageUrl,
      appVersion,
      noIndex
    } = this.props;

    const title =
      pageTitle && `${pageTitle}${excludeLibraryFromMetaTitle ? '' : ` — ${currentLibrary.get('fullName')}`}`;
    const twitterHandle = currentLibrary.get('twitterHandle');
    const description = this.getDescription();

    return [
      { charset: 'utf-8' },
      { property: 'og:site_name', content: currentLibrary.get('fullName') },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
      { name: 'format-detection', content: 'telephone=no' },
      { name: 'App-Version', content: appVersion },
      { name: 'description', content: description },
      { property: 'og:description', content: description },
      { property: 'twitter:description', content: description },
      { property: 'fb:app_id', content: '821053187955346' },
      { property: 'og:type', content: 'website' },
      ...(pageUrl ? [{ property: 'og:url', content: pageUrl }] : []),
      ...(title
        ? [
            { property: 'title', content: title },
            { property: 'og:title', content: title },
            { property: 'twitter:title', content: title }
          ]
        : []),
      ...(imageUrl
        ? [
            { property: 'og:image', content: imageUrl },
            { property: 'og:image:width', content: '268' },
            { property: 'og:image:height', content: '394' },
            { property: 'twitter:image', content: imageUrl },
            { property: 'twitter:card', content: 'summary_large_image' }
          ]
        : [{ property: 'twitter:card', content: 'summary' }]),
      ...(twitterHandle
        ? [
            { property: 'twitter:creator', content: `@${twitterHandle}` },
            { property: 'twitter:site', content: `@${twitterHandle}` }
          ]
        : []),
      ...(noIndex ? [{ name: 'robots', content: 'noindex' }] : [])
    ];
  }

  getScripts() {
    const { currentLibrary, libraryLinkedData, linkedData } = this.props;
    const scripts = [];

    // Remove null values from json output
    const stringify = data => JSON.stringify(data, (key, val) => (val === null ? undefined : val));

    if (currentLibrary.get('linkedDataEnabled')) {
      scripts.push({
        type: 'application/ld+json',
        innerHTML: stringify(libraryLinkedData.toJS())
      });

      if (linkedData) {
        scripts.push({
          type: 'application/ld+json',
          innerHTML: stringify(linkedData.toJS())
        });
      }
    }

    return scripts;
  }

  render() {
    const { currentLibrary } = this.props;

    const libraryFullName = currentLibrary.get('fullName');
    const defaultTitle = `${libraryFullName} | BiblioCommons`;

    return (
      <Helmet
        defaultTitle={defaultTitle}
        title={this.getDocumentTitle()}
        script={this.getScripts()}
        meta={this.getMetaTags()}
        link={this.getLinks()}
      />
    );
  }
}

DocumentHead.contextType = Context;

DocumentHead.defaultProps = {
  excludeLibraryFromMetaTitle: false
};

DocumentHead.propTypes = {
  excludeLibraryFromMetaTitle: PropTypes.bool,
  pageTitle: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  linkedData: ImmutablePropTypes.map,
  noIndex: PropTypes.bool,
  pageUrl: PropTypes.string,
  appVersion: PropTypes.string.isRequired,
  currentLibrary: libraryShape.isRequired,
  libraryLinkedData: ImmutablePropTypes.map.isRequired
};

export default injectIntl(DocumentHead);
