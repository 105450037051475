import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ErrorPage from 'app/components/ErrorPage';
import MainLayout from 'app/components/layouts/MainLayout';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import BrowsePageRoute from './BrowsePageRoute';

export default (
  <Route path="/v2">
    <MainLayout>
      <Switch>
        <Route exact path="/v2">
          <Redirect to="/v2/browse" />
        </Route>
        <Route exact path="/v2/browse">
          <BrowsePageRoute />
        </Route>
        <Route>
          <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
