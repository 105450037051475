import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { EVENTS_URL, MY_EVENTS_BASE_URL } from 'app/constants/EventsConstants';
import connectRoute from 'app/routes/connectRoute';
import { selectCurrentAccount } from 'app/selectors/AuthSelector';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import EventsActions from 'app/actions/EventsActions';
import ErrorPage from 'app/components/ErrorPage';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';

const MyEventsPageContainer = loadable(() => import('app/components/events/MyEventsPage/MyEventsPageContainer'));

const pastEventsRoute = new RegExp(`${MY_EVENTS_BASE_URL}/past`);

export function MyEventsPageRoute(props) {
  const currentLibrary = useSelector(selectCurrentLibrary);

  if (!currentLibrary.getIn(['events', 'enabled']) || !currentLibrary.getIn(['events', 'myEventsEnabled'])) {
    return (
      <ErrorPage exploreLinkUrl={EVENTS_URL} httpStatusCode={HTTP_STATUS_CODE.FORBIDDEN} pageMessageKey="my_events" />
    );
  }
  return <MyEventsPageContainer {...props} />;
}

function getRouteData({ dispatch, state, query, location, params }) {
  const account = selectCurrentAccount(state);
  const currentLibrary = selectCurrentLibrary(state);

  if (!currentLibrary.getIn(['events', 'enabled']) || !currentLibrary.getIn(['events', 'myEventsEnabled'])) return [];

  const accountId = account && account.get('id');
  if (!accountId) return [];

  if (params?.status) {
    query[params?.status] = true;
  }

  if (pastEventsRoute.test(location.pathname)) {
    query.past = true;
  }

  return [dispatch(EventsActions.getMyEvents(query))];
}

export default connectRoute(MyEventsPageRoute, {
  getRouteData,
  debounceOptions: {
    wait: 100 // milliseconds
  }
});
