import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { DocumentHeadContainer } from 'app/components/shared/DocumentHead';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import ErrorPage from './ErrorPage';

const DEFAULT_TITLE = {
  [HTTP_STATUS_CODE.FORBIDDEN]: '403 Forbidden',
  [HTTP_STATUS_CODE.NOT_FOUND]: '404 Not Found'
};

const getBodyMessageId = (httpStatusCode, pageMessageKey) => {
  switch (httpStatusCode) {
    case HTTP_STATUS_CODE.FORBIDDEN:
      return `errors.forbidden.description_${pageMessageKey || 'default'}`;
    case HTTP_STATUS_CODE.NOT_FOUND:
      return `errors.not_found.description_${pageMessageKey || 'default'}`;
    default:
      throw new Error(`missing body messsage ID for HTTP status code: ${httpStatusCode}`);
  }
};

const getTitleMessageId = (httpStatusCode, pageMessageKey) => {
  switch (httpStatusCode) {
    case HTTP_STATUS_CODE.FORBIDDEN:
      return `errors.forbidden.title_${pageMessageKey || 'default'}`;
    case HTTP_STATUS_CODE.NOT_FOUND:
      return `errors.not_found.title_${pageMessageKey || 'default'}`;
    default:
      throw new Error(`missing title messsage ID for HTTP status code: ${httpStatusCode}`);
  }
};

const ErrorPageContainer = ({ exploreLinkMessageId, exploreLinkUrl, httpStatusCode, pageMessageKey }) => {
  const intl = useIntl();
  const currentLibrary = useSelector(selectCurrentLibrary);

  const title = intl.formatMessage({
    defaultMessage: DEFAULT_TITLE[httpStatusCode],
    id: getTitleMessageId(httpStatusCode, pageMessageKey)
  });

  return (
    <>
      <DocumentHeadContainer noIndex pageTitle={title} />
      <ErrorPage
        bodyMessageId={getBodyMessageId(httpStatusCode, pageMessageKey)}
        exploreLinkMessageId={exploreLinkMessageId}
        exploreLinkUrl={exploreLinkUrl}
        libraryName={currentLibrary.get('longName')}
        title={title}
      />
    </>
  );
};

ErrorPageContainer.propTypes = {
  exploreLinkMessageId: PropTypes.string,
  exploreLinkUrl: PropTypes.string,
  httpStatusCode: PropTypes.oneOf(Object.values(HTTP_STATUS_CODE)).isRequired,
  pageMessageKey: PropTypes.string
};

export default ErrorPageContainer;
