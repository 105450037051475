import PropTypes from 'prop-types';
import React from 'react';

import { Link } from '@bibliocommons/base-links';
import { FormattedHTMLMessage } from '@bibliocommons/react-intl';

import './ErrorPage.scss';

const ErrorPage = ({ bodyMessageId, exploreLinkMessageId, exploreLinkUrl, libraryName, title }) => {
  return (
    <div className="cp-error-page container">
      <h1>{title}</h1>
      <p>
        <FormattedHTMLMessage id={bodyMessageId} values={{ libraryName }} />
      </p>
      <Link href={exploreLinkUrl} dataKey="explore_link">
        <FormattedHTMLMessage id={exploreLinkMessageId} />
      </Link>
    </div>
  );
};

ErrorPage.defaultProps = {
  exploreLinkMessageId: 'go_to_home_page',
  exploreLinkUrl: '/',
  libraryName: ''
};

ErrorPage.propTypes = {
  bodyMessageId: PropTypes.string.isRequired,
  exploreLinkMessageId: PropTypes.string,
  exploreLinkUrl: PropTypes.string,
  libraryName: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default ErrorPage;
