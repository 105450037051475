import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from 'app/routes/AuthenticatedRoute';
import AccountRequiredRoute from 'app/routes/AccountRequiredRoute';
import ErrorPage from 'app/components/ErrorPage';
import MainLayout from 'app/components/layouts/MainLayout';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import MyEventsPageRoute from './MyEventsPageRoute';

const basePath = '/v2/events/account';

export default (
  <Route path={basePath}>
    <AuthenticatedRoute>
      <AccountRequiredRoute>
        <MainLayout>
          <Switch>
            <Route exact path={`${basePath}/upcoming`}>
              <MyEventsPageRoute />
            </Route>
            <Route exact path={`${basePath}/upcoming/:status`}>
              <MyEventsPageRoute />
            </Route>
            <Route exact path={`${basePath}/past`}>
              <MyEventsPageRoute past />
            </Route>
            <Route exact path={`${basePath}/past/:status`}>
              <MyEventsPageRoute past />
            </Route>
            <Redirect exact from={basePath} to={`${basePath}/upcoming`} />
            <Route>
              <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />
            </Route>
          </Switch>
        </MainLayout>
      </AccountRequiredRoute>
    </AuthenticatedRoute>
  </Route>
);
