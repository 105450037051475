import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { libraryShape } from '@bibliocommons/bc-prop-types';
import { selectCurrentLibrary, selectLibraryLinkedData } from 'app/selectors/LibrarySelector';
import { selectAppConfig } from 'app/selectors/AppSelector';
import DocumentHead from './DocumentHead';

export class DocumentHeadContainer extends React.PureComponent {
  render() {
    return <DocumentHead {...this.props} />;
  }
}

DocumentHeadContainer.defaultProps = {
  excludeLibraryFromMetaTitle: false
};

DocumentHeadContainer.propTypes = {
  // ownProps
  excludeLibraryFromMetaTitle: PropTypes.bool,
  pageTitle: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  linkedData: ImmutablePropTypes.map,
  noIndex: PropTypes.bool,
  pageUrl: PropTypes.string,

  // Redux props
  appVersion: PropTypes.string,
  currentLibrary: libraryShape.isRequired,
  libraryLinkedData: ImmutablePropTypes.map.isRequired
};

const mapStateToProps = state => {
  const appConfig = selectAppConfig(state);

  return {
    currentLibrary: selectCurrentLibrary(state),
    libraryLinkedData: selectLibraryLinkedData(state),
    appVersion: appConfig.get('appVersion')
  };
};

export default connect(mapStateToProps)(DocumentHeadContainer);
