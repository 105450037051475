import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate } from 'react-intl';
import moment from 'moment';

// value can be an ISO formatted string - ex., '2021-05-14T11:28:31-04:00'
// or a timestamp, ex., 1621006111000
const ShortFormattedDate = ({ value }) => {
  if (value) {
    return (
      <FormattedDate value={moment.utc(value).toDate()} year="numeric" month="short" day="2-digit">
        {message => <span className="cp-short-formatted-date">{message}</span>}
      </FormattedDate>
    );
  }

  return null;
};

ShortFormattedDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default ShortFormattedDate;
