import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorPage from 'app/components/ErrorPage';
import MainLayout from 'app/components/layouts/MainLayout';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import UsersPageRoute from './UsersPageRoute';
import ShelfPageRoute from './ShelfPageRoute';

const basePath = '/v2/users/:userId';

export default (
  <Route path={basePath}>
    <MainLayout deprecated>
      <UsersPageRoute>
        <Switch>
          <Route path={`${basePath}/shelves/:shelfName`}>
            <ShelfPageRoute />
          </Route>
          <Route>
            <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />
          </Route>
        </Switch>
      </UsersPageRoute>
    </MainLayout>
  </Route>
);
