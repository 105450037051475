import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import { selectAppConfig } from 'app/selectors/AppSelector';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import EventsActions from 'app/actions/EventsActions';

const EventsBrochureContainer = loadable(() => import('app/components/events/EventsBrochure/EventsBrochureContainer'));

function getRouteData({ state, dispatch, location, query }) {
  const appConfig = selectAppConfig(state);
  const currentLibrary = selectCurrentLibrary(state);
  if (!currentLibrary.getIn(['events', 'enabled'])) return [];

  const sourcePage = `${appConfig.get('baseURL')}/v2/events${location.search ? `${location.search}` : ''}`;
  if (appConfig.get('referer') && appConfig.get('referer') === sourcePage) {
    return [dispatch(EventsActions.fetchEventsForBrochure(query))];
  }
  return [];
}

export default connectRoute(EventsBrochureContainer, {
  getRouteData
});
